
export default {
  name: 'OrganismContentSliderBrochure',
  props: {
    sliderContentData: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
